import type { FC } from 'react'

import { useEffect, useState, useRef, useCallback } from 'react'

import { usePaymentContext } from '@/shared/contexts/payment-context'
import { UserPaymentMethodPublicWithData } from '@/shared/contexts/payment-context'
import { formatLocalizationString } from '@/shared/utils'
import { SavedCardDisplay } from '@/shared/components/saved-card-display'
import { NewCard } from '@/shared/components/new-card'
import { getCardType } from '@/shared/hooks/use-input-mask'
import { PaymentSystemTypes } from '@/shared/components/saved-card-display/saved-card-display.types'
import { useLanguage } from '@/shared/contexts/language-context'
import { Typography } from '@/shared/components/typography'
import { SavedCardProps } from './saved-card.types'
import { ReactComponent as CreditCardIcon } from '@/shared/icons/credit-card-icon.svg'
import { ReactComponent as DotsGroup } from '@/shared/icons/dots-group.svg'
import { ReactComponent as DotsGroupWhite } from '@/shared/icons/dots-group-white.svg'

import styles from './saved-card.module.scss'
import classnames from 'classnames'
import { getCssVariable } from '@/shared/utils/get-css-variable'
import { useMediaContext } from '@/shared/contexts/media-context/media-context'
import {
  PAYMENT_FORM_PAGE_DATA_ID,
  PAYMENT_PAGE_DATA_ID,
} from '@/shared/constants/test/data-testid'
import { postEventToParent } from '@/shared/utils/post-event-po-parent'
import { ParentEvents } from '@/shared/constants/parent-events'
import { useSearchParams } from '@/shared/contexts/search-params-context'
import { SearchParams } from '@/shared/constants/search-params'

export const SavedCards: FC<SavedCardProps> = ({
  onMoreOpen,
  onMoreClose,
  isMoreOpen,
}) => {
  const dropdownElementRef = useRef<HTMLDivElement | null>(null)
  const [dropdownHeigh, setDropDownHeight] = useState<number | undefined>(
    undefined,
  )
  const { isScreenSm } = useMediaContext()
  const { params } = useSearchParams()

  const openFromHub = params.hasOwnProperty(SearchParams.OpenFromHub)

  const { setSavedCard, savedCard, setCardForPayment, savedCards } =
    usePaymentContext()
  const { getField } = useLanguage()

  const [isActiveNewCard, setIsActiveNewCard] = useState(false)
  const [isActiveOtherMethods, setIsOtherMethods] = useState(false)

  const handleNewCard = () => {
    setSavedCard(null)
    if (!isActiveNewCard) {
      setIsActiveNewCard(true)
    }
    onMoreClose()
  }

  const handleOtherMethods = () => {
    postEventToParent({ event: ParentEvents.MethodsPageOpen })
  }

  const [visibleCards, setVisibleCards] = useState<typeof savedCards>(
    isScreenSm ? savedCards : savedCards?.slice(0, 2),
  )
  const [hiddenCards, setHiddenCards] = useState<typeof savedCards>(
    isScreenSm ? [] : savedCards?.slice(2),
  )

  useEffect(() => {
    setVisibleCards(isScreenSm ? savedCards : savedCards?.slice(0, 2))
    setHiddenCards(isScreenSm ? [] : savedCards?.slice(2))
    onMoreClose()
  }, [isScreenSm])

  const handleSavedCard = useCallback(
    (item: UserPaymentMethodPublicWithData) => {
      setIsActiveNewCard(false)

      if (savedCard?.id === item.id) {
        setSavedCard(null)
      } else {
        setSavedCard(item)
      }

      setCardForPayment((prev) => ({
        ...prev,
        system: getCardType(item.data.card_number_first6) as PaymentSystemTypes,
      }))

      onMoreClose()
    },
    [savedCards, savedCard, onMoreClose],
  )

  const openOtherCards = () => {
    setIsOtherMethods(false)
    setIsActiveNewCard(false)
    onMoreOpen()
  }

  const closeOtherCards = () => {
    setIsActiveNewCard(false)
    setIsOtherMethods(false)
    onMoreClose()
  }

  useEffect(() => {
    if (isScreenSm) return
    if (savedCard) {
      const filteredCards = savedCards.filter(
        (card) => card.id !== savedCard.id,
      )
      setVisibleCards(
        filteredCards.length ? [savedCard, filteredCards[0]] : [savedCard],
      )
      setHiddenCards(filteredCards.slice(1))
    } else {
      setVisibleCards(savedCards.slice(0, 2))
      setHiddenCards(savedCards.slice(2))
    }
  }, [savedCard])

  useEffect(() => {
    if (dropdownElementRef) {
      // Перерасчет высоты сохраненных карт при сесайзе  окна
      const resizeObserver = new ResizeObserver(() => {
        if (dropdownElementRef.current) {
          const dropdownPosition =
            dropdownElementRef.current?.getBoundingClientRect()
          const padding = Number(
            getCssVariable(
              '--card-section-bottom',
              dropdownElementRef.current,
            ).replace(/px/, ''),
          )

          if (dropdownPosition?.y) {
            // dropdownPosition.y - top блока
            // window.innerHeight – высота экрана
            // padding – внутренний отступ
            setDropDownHeight(
              window.innerHeight -
                dropdownPosition.y -
                (Number.isNaN(padding) ? 0 : padding),
            )
          } else {
            setDropDownHeight(undefined)
          }
        }
      })

      resizeObserver.observe(window.document.body)

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [dropdownElementRef])

  return (
    <div className={classnames(styles['container'])}>
      <div className={styles['card-section-top-wrapper']}>
        <div
          className={styles['card-section-top']}
          data-testid={PAYMENT_FORM_PAGE_DATA_ID.SCROLL_SAVED_CARDS_CONTAINER}
        >
          {visibleCards?.map((item) => (
            <SavedCardDisplay
              key={item.id}
              data-testid={PAYMENT_FORM_PAGE_DATA_ID.SCROLL_SAVED_CARD}
              className={styles['saved-card']}
              type={
                getCardType(item.data.card_number_first6) as PaymentSystemTypes
              }
              isActive={item.id === savedCard?.id}
              card_number={item.data.card_number_last4}
              onClick={() => handleSavedCard(item)}
            />
          ))}

          {process.env.REACT_APP_TYPE === 'pci_dss' &&
            (visibleCards.length > 0 || openFromHub) && (
              <NewCard
                onClick={handleNewCard}
                icon={
                  <CreditCardIcon
                    className={classnames(
                      styles['new-card-icon'],
                      isActiveNewCard && styles['new-card-icon_active'],
                    )}
                  />
                }
                isActive={isActiveNewCard}
                className={styles['saved-card']}
                text={getField('cards_new_card_title')}
              />
            )}

          {openFromHub && (
            <NewCard
              onClick={handleOtherMethods}
              icon={isActiveOtherMethods ? <DotsGroupWhite /> : <DotsGroup />}
              isActive={isActiveOtherMethods}
              className={styles['saved-card']}
              text={getField('payment_method_other_method_title')}
              testId={PAYMENT_PAGE_DATA_ID.PAYMENT_METHOD_SELECT_BTN}
            />
          )}
        </div>
      </div>

      {hiddenCards.length ? (
        <>
          {isMoreOpen ? (
            <div
              onClick={closeOtherCards}
              className={styles['button-choose-card-amount']}
            >
              <CreditCardIcon className={styles['hidden-cards-icon']} />
              <Typography
                tag="span"
                fontSize="11"
                color="region-surface-on-surface-contrast-highest"
                lineHeight="15"
              >
                {getField('payment_card_hide_button')}
              </Typography>
            </div>
          ) : (
            <div
              onClick={openOtherCards}
              className={styles['button-choose-card-amount']}
              data-testid={PAYMENT_FORM_PAGE_DATA_ID.EXPAND_SAVED_CARDS_BTN}
            >
              <CreditCardIcon className={styles['hidden-cards-icon']} />
              <Typography
                tag="span"
                fontSize="11"
                color="region-surface-on-surface-contrast-highest"
                lineHeight="15"
              >
                {getField('payment_card_change_button')}
              </Typography>
              <Typography
                tag="span"
                fontSize="11"
                color="region-surface-on-surface-contrast-low"
                lineHeight="15"
              >
                {formatLocalizationString(
                  getField('payment_card_change_button_description'),
                  {
                    number: hiddenCards?.length ?? undefined,
                  },
                )}
              </Typography>
            </div>
          )}
        </>
      ) : null}
      <div
        ref={dropdownElementRef}
        className={styles['card-section-bottom-container']}
        style={{
          height: isMoreOpen ? dropdownHeigh : 0,
        }}
      >
        {isMoreOpen && (
          <div
            className={styles['card-section-bottom']}
            data-testid={
              PAYMENT_FORM_PAGE_DATA_ID.EXPANDED_SAVED_CARDS_CONTAINER
            }
          >
            {hiddenCards?.map((item) => (
              <SavedCardDisplay
                key={item.id}
                data-testid={PAYMENT_FORM_PAGE_DATA_ID.EXPANDED_SAVED_CARD}
                className={styles['saved-card-bottom']}
                type={
                  getCardType(
                    item.data.card_number_first6,
                  ) as PaymentSystemTypes
                }
                isActive={item.id === savedCard?.id}
                card_number={item.data.card_number_last4}
                onClick={() => handleSavedCard(item)}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
