import { createElement, type FC } from 'react'
import type {
  PaymentSystemTypes,
  SavedCardDisplayProps,
} from './saved-card-display.types'

import { ReactComponent as Ellipse } from '@/shared/icons/ellipse-light.svg'
import { ReactComponent as VisaIcon } from '@/shared/icons/visa-light.svg'
import { ReactComponent as MirIcon } from '@/shared/icons/mir-light.svg'
import { ReactComponent as MastercardIcon } from '@/shared/icons/mastercard-light.svg'

import styles from './saved-card-display.module.scss'
import clsx from 'clsx'

const Icons: Record<PaymentSystemTypes, FC> = {
  mastercard: MastercardIcon,
  visa: VisaIcon,
  mir: MirIcon,
  '': () => <></>,
}

export const SavedCardDisplay: FC<SavedCardDisplayProps> = ({
  type = 'visa',
  isActive = false,
  onClick = () => {},
  card_number,
  className,
  ...props
}) => {
  const containerClasses = clsx(
    styles['container'],
    className,
    isActive && styles['container_active'],
  )
  const bottomSlotClasses = clsx(
    styles['bottom-slot'],
    styles[`bottom-slot_${type}`],
    isActive && styles[`bottom-slot_active`],
  )
  const numberClasses = clsx(
    styles['number'],
    isActive && styles['number_active'],
  )

  return (
    <div onClick={onClick} className={containerClasses} {...props}>
      <div className={styles['content']}>
        <div className={styles['top-slot']}>
          <div
            className={clsx(
              styles['ellipses'],
              isActive && styles['ellipses_active'],
            )}
          >
            <Ellipse />
          </div>
          <div className={numberClasses}>{card_number}</div>
        </div>
        <div className={bottomSlotClasses}>
          {type ? createElement(Icons[type]) : null}
        </div>
      </div>
    </div>
  )
}
